import React, { useContext, useState, useEffect } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"
import axios from "axios"
import {
  GATSBY_GET_ORDER_DETAILS_WEBHOOK,
  GATSBY_UPLOAD_PROOF_OF_PAYMENT_WEBHOOK,
} from "gatsby-env-variables"

import Layout from "layout"
import Container from "layout/Container"
import Section from "elements/Section" // Was section new
import ActionButtons from "elements/ActionButtons"
import UploadDropzone from "elements/Upload"
import ProofOfPaymentBankDetails from "./components/ProofOfPaymentBankDetails"
import ProofOfPaymentOrderSummary from "./components/ProofOfPaymentOrderSummary"

import { AppContext } from "context/AppContext"
import { 
  hasZendeskID, 
  hasCorrectEmail, 
  hasCorrectStatus,
  isOrder,
} from "./services/redirects"
import { useProofOfPaymentImages } from "./hooks/useProofOfPaymentImages"
import { isBrowser } from "services/general"

const MAX_FILE_SIZE_IN_BYTES = 5242880
const MAX_FILE_COUNT = 6

const ProofOfPayment = ({ location }) => {
  const { state } = useContext(AppContext)
  const { documents } = state

  const urlSearchParams = new URLSearchParams(location.search)
  const zendeskId = urlSearchParams.get("z")?.replace("/", "") || ""
  const [filesUploaded, setFilesUploaded] = useState(documents)
  const [notifications, setNotifications] = useState([])
  const [loading, setLoading] = useState(false)
  const [orderDetails, setOrderDetails] = useState(null)
  
  const imgData = useProofOfPaymentImages()
  const proofOfPaymentImg = imgData?.proof_of_payment?.childImageSharp?.fixed
  
  // Pass this so that logging in will redirect back to this page
  const locationState = { 
    redirectTo: `${location.pathname}${location.search}`, 
    zendeskId: zendeskId, 
    failedVerificationPath: "/",
  }

  // Obtains the details of the order from Airtable through Make
  useEffect(() => {
    const getOrderDetails = async () => {
      let response = await axios.post(GATSBY_GET_ORDER_DETAILS_WEBHOOK, {
        zendeskId,
      })
      setOrderDetails(response?.data || null)
    }
    if (!!zendeskId) getOrderDetails()
  }, [zendeskId])

  if (isBrowser()) {
    // Functions checking if user can access the page
    hasZendeskID(zendeskId)
    if (orderDetails) {
      isOrder(orderDetails)
      hasCorrectStatus(orderDetails)
    }
  }

  // Used in submit button to make sure everything has a token before you can click submit
  const allDocumentsHaveToken = state.documents.every((file) => {
    return file.hasOwnProperty('token')
  })  

  const handleSubmitProofOfPayment = async () => {
    setLoading(true)      
    await axios.post(GATSBY_UPLOAD_PROOF_OF_PAYMENT_WEBHOOK, {
      documents: state.documents,
      uploadTokens: state.documents?.map((fileUploaded) => fileUploaded.token),
      zendeskId,
    })
    setLoading(false)
    navigate("/proof-of-payment/complete")
  }

  return (
    <Layout
      title="Payment Form"
      seoTitle="Payment"
      subtitle="Take a photo or upload a scanned copy of the payment confirmation from your bank."
      // hasHexagons
      // isPrivate
      isVerifyNamePage
      locationState={locationState}
    >
      <Container isCentered>
        {orderDetails && <ProofOfPaymentOrderSummary orderDetails={orderDetails} zendeskId={zendeskId} />}
        <ProofOfPaymentBankDetails dispensingChannel={orderDetails?.dispensingChannel} />
        <Formik
          initialValues={{ documents: [] }}
          onSubmit={handleSubmitProofOfPayment}
        >
          {() => (
            <Form>
              <Section 
                title="Upload Proof Of Payment" 
                subtitle="Take a photo or upload a scanned copy of the payment confirmation from your bank."
                className="is-size-6 mt-1 mb-4"
                isSectionRequired
              >
                {filesUploaded?.length <= MAX_FILE_COUNT ? (
                  <UploadDropzone
                    label="Upload Proof of Payment"
                    icon={ proofOfPaymentImg } 
                    notifications={notifications}
                    filesUploaded={filesUploaded}
                    setFilesUploaded={setFilesUploaded}
                    filesUploadedLimit={MAX_FILE_COUNT}
                    maxFileSize={MAX_FILE_SIZE_IN_BYTES}
                    docType="pop"
                  />
                ) : null}
              </Section>
              <ActionButtons
                submit={{
                  label: "Submit",
                  loading,
                  disabled: state.documents.length < 1 || !allDocumentsHaveToken,
                }}
                hasCaptcha={true}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default ProofOfPayment